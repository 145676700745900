import { gql } from "@apollo/client/core";

export const typeDefs = gql`
  schema {
    query: Query
  }

  "Indicates an Input Object is a OneOf Input Object."
  directive @oneOf on INPUT_OBJECT

  type Account {
    assetId: AssetId
    holdings: Holding
    isExcludedFromProfitCalculation: Boolean
    keyValues: KeyValues
    marketValues: [PortfolioInsightMarketValue!]
    transactions: [PortfolioInsightTransaction!]
  }

  type AccountOperator {
    """
    The account operator. E.g 'EIK', 'DAB', 'F'
    """
    accountOperatorCode: String
    """
    The account name in norwegian. E.g 'EIKA', 'DANSE BANK'
    """
    accountOperatorName: String
  }

  type AccumulatedBalance {
    age: Int
    balance: Int
    date: ISO8601Date
    maxBalance: Int
    minBalance: Int
  }

  type ActiveState {
    active: Boolean
    name: String
    prettyName: String
  }

  type AdditionalDataPrognosis {
    balancePayoutStart: Int
    balancePayoutStartMax: Int
    balancePayoutStartMin: Int
  }

  type AgeCoverage {
    annualAmount: Float
    coverageName: CoverageName
    coverageTypeCode: String
    fromAndIncludingAge: Int
    lifelongPayout: Boolean
    toNotIncludingAge: Int
  }

  """
  ## Alis Contracts

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type AlisContract implements Contract {
    """
    Boolean flag indicating whether the contract is active contract
    """
    activeContract: Boolean
    """
    The total balance on the contract. The balance amount may differ from the amount available for withdrawal
    """
    balance: Float
    contractNavigation: ContractNavigation
    """
    Contract number in alis
    """
    contractNumber: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    ID is on the format {contractNumber}:ALIS_A
    """
    id: ID
    """
    Boolean flag indicating whether the contract is classified as an insurance contract
    """
    insuranceContract: Boolean
    """
    Boolean flag indicating whether the customer is the joint owner of the contract
    """
    jointOwner: Boolean
    """
    Boolean flag indicating whether the customer is the main owner of the contract
    """
    mainOwner: Boolean
    """
    How much the customer pays for the contract on a yearly basis
    """
    premiumYearlyAmount: Float
    """
    Product Area for ALIS contract. Only for internal use.
    """
    productArea: String
    """
    Internal use. Code used to identify the contract type
    """
    productMasterCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    List of roles on the contract
    """
    roles: [AlisRole!]
    """
    Boolean flag indicating whether the contract is classified as a savings contract
    """
    savingsContract: Boolean
    """
    Boolean flag indicating whether the contract is eligible for prognosis simulation
    """
    simulatable: Boolean
  }

  """
  ## Alis Role

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type AlisRole {
    addressingCode: String
    """
    The customer number of the role holder
    """
    cmId: Int
    """
    Flag indicating whether the role holder is the owner of the contract
    """
    ownerFlag: String
    """
    Flag indicating whether the role holder is the payer for the contract
    """
    payerFlag: String
    """
    Internal code used to classify the role
    """
    roleCode: String
    """
    Flag indicating whether the role holder is dead
    """
    roleDeadFlag: String
    """
    Text explaining the role
    """
    roleText: String
  }

  type Amount {
    currency: String
    value: Float
  }

  type AskTaxDetails {
    depositInNok: Float
    unusedDeductableRiskFreeReturnInNok: Float
  }

  type AssetId {
    accountId: String
    participantId: String
    systemArea: SystemArea
  }

  type AvailableAction {
    actionNavigationLink: String!
    actionStatus: AvailableActionStatus!
    actionType: AvailableActionTypes!
    supportExitUrl: Boolean!
  }

  enum AvailableActionStatus {
    ACTION_ALLOWED
    ACTION_NOT_ALLOWED
    INFORMATION_NEEDED
  }

  enum AvailableActionTypes {
    ASK_MOVE
    BACK_TO_COLLECTIVE
    BUY_FUND
    CONVERT_TO_FMI
    CREATE_PERIODIC_DEPOSIT
    DOWNSCALING
    EDITABLE_NAME
    FUND_SWITCH
    LUMPSUM_DEPOSIT
    PENSION_WITHDRAWAL
    SAVING_AGREEMENTS
    SELL_FUND
    TRADE_BANK_ACCOUNT
    UPDATE_PERIODIC_DEPOSIT
    WITHDRAWAL
  }

  """
  # UCITSAccount

  #### OIDC scopes
  * Realm storebrand  - storebrand.bank.private
  * Realm corporate   - TODO
  """
  type BankAccount {
    accountNumber: String
    agreementActive: Boolean
    agreementValidToDate: ISO8601Date
    aliasName: String
    bankAgreementStatus: String
    bankAgreementType: String
    bookBalance: Float
    cardTypeName: String
    cardValidToDate: String
    contractNavigation: ContractNavigation
    createdDate: String
    creditLimit: Float
    currency: String
    currentInterestRate: Float
    disposableBalance: Float
    effectiveInterestRate: Float
    """
    ID is on the format {accountNumber}:SDC_A
    """
    id: ID
    instalmentPauseFromDate: ISO8601Date
    instalmentPauseToDate: ISO8601Date
    mortgageTypeName: String
    participants: [BankAccountParticipant!]
    paymentDueDay: Int
    paymentFrequency: String
    productArea: String
    productCode: String
    productFriendlyName: String!
    productLevel3: ProductLevel
    productLevel4: ProductLevel
    reservedAmount: Float
  }

  type BankAccountParticipant {
    agreementDetailedCode: String
    customerIdentificator: String
    participantId: String
    participantName: String
    participantTypeName: String
  }

  input BankOpenPrognosisInput {
    balance: Float
    birthYear: Int
    gender: String
    monthsOffset: Int
    payoutDurationYears: Int
    periodicDeposit: Int
    startPayoutAge: Int
  }

  type BaseAmount {
    amount: Int
    validFrom: ISO8601Date
  }

  type Beneficiary {
    """
    The percentage of the coverage that should go to the beneficiary
    """
    coveragePercentage: Float
    """
    The code for the coverage type
    """
    coverageTypeCode: String
    """
    The first name of the beneificiary
    """
    firstName: String
    """
    The last name of the beneficiary
    """
    lastName: String
  }

  type CashHoldings {
    amount: Float
    currency: String
    partOfAmountInTransit: Float
  }

  """
  # Shared details between contracts
  """
  interface Contract {
    id: ID
    productArea: String
  }

  type ContractNavigation {
    availableActions: [AvailableAction!]
    contractDetailsLink: String
  }

  type CostPrice {
    amount: Float
    currency: String
  }

  type CoverageName {
    english: String
    norwegian: String
  }

  type Coverages {
    ageCoverages: [AgeCoverage!]
    conditionalAgeCoverages: [AgeCoverage!]
    insuranceCoverages: [InsuranceCoverage!]
  }

  type DebtorInfo {
    approved: String
    bban: String
    cancelled: String
    kid: String
  }

  type Deferred {
    from: String
    to: String
  }

  type Description {
    en: String
    no: String
  }

  type EkstrapensjonEmploymentContract implements Contract & LinkContractAbstract {
    """
    Account id in ProLink
    """
    accountId: String
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    beneficiaries: [Beneficiary!]
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    """
    When the contract was "trådt i kraft". Will be 1980-01-01 until the first investment in the funds.
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    employer: Employer
    """
    The amount the employer saves yearly for the customer. The amount is invested according to the distribution percentage in investmentProfile
    """
    employerAnnualSavingsAmount: Float
    """
    The KID number customer can use to transfer money from their own bank account to the ProLink contract.
    Money transferred will be invested according setting of the product propeties to the contract's product code.
    The three options are 0. "Don't invest" 1. "Invest according to the current holdings profile" 2. "Invest according to the investment profile"
    The current choice for the products in ProLink is 2. Invest according to the investment profile
    """
    fixedKid: String
    historicMarketValues: [HistoricMarketValue!]
      @deprecated(reason: "This field is depracated, use portfolioInsight.accounts.marketValues[]")
    holdings: Holding
    """
    ID is on the format {participantId}-{accountId}:PL_A.
    """
    id: ID
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    The bank account number in ProLink used when doing one time payments using the fixed kid
    """
    oneTimeDepositAccountNumber: String
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile
    """
    redistributionProfile: [RedistributionProfile!]
    reductionFund: ReductionFund
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use KeyValues instead")
    roles: [LinkRole!]
    """
    Savings agreement or the the periodic deposits that customer does
    """
    savingsAgreements: [SavingsAgreement!]
    selectableFunds: [SelectableFund!]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    specialRetirementAge: Boolean
    """
    trade account number in ProLink
    """
    tradeAccountSell: String
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
  }

  type Employer {
    name: String
    """
    Can be null when norskpensjon due to the integration not returing organizationNumber
    """
    organizationNumber: String
  }

  """
  Boolean flags indicating whether customer has contracts
  individual = Alis contracts
  pensionFund = Abakus contracts
  """
  type EngagementFlag {
    individual: Boolean
    pensionFund: Boolean
  }

  """
  ## Egen pensjonskonto

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type EpkContract implements Contract {
    """
    Account id in ProLink
    """
    accountId: String
    accountOperator: AccountOperator
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    annualPremium: Float
    applicableVacancyRate: Float
    calculatedNationalInsuranceBasicAmount: Float
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    coverages: Coverages
    """
    Date for first deposit in prolink
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    employedDate: String
    employer: Employer
    """
    The amount the employer saves yearly for the customer. The amount is invested according to the distribution percentage in investmentProfile
    """
    employerAnnualSavingsAmount: Float
    employmentPercentage: Float
    historicMarketValues: [HistoricMarketValue!]
      @deprecated(reason: "This field is depracated, use portfolioInsight.accounts.marketValues[]")
    holdings: Holding
    """
    ID is on the format {participantId}-{accountId}:PL_A.
    """
    id: ID
    investmentProfile: [InvestmentProfile!]
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    pensionQualifyingIncome: Float
    pensionSchemeInclusionDate: String
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    pkrEpkId: String
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    reductionFund: ReductionFund
    renewalDetails: RenewalDetail
    retirementAge: Int
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use keyValues instead")
    roles: [LinkRole!]
    salary: Float
    salaryHistory: [SalaryHistory!]
    savingsRate: SavingsRate
    selectableFunds: [SelectableFund!]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
    """
    Contract number in USB
    """
    usbContractNumber: String
    """
    Contract type in USB
    """
    usbContractType: String
    """
    Customer number in USB
    """
    usbPersonCustomerNumber: String
    """
    Product code in USB
    """
    usbProductCode: String
    usbTermDuration: UsbTermDuration
  }

  """
  ## Egen pensjonskonto contract without the investement.
  The investement has been moved internally to an egen pensjonskonto fleksibel or to a different penion proivder

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type EpkEmploymentContract implements Contract {
    """
    "The account operator. E.g 'EIK', 'DAB', 'F'"
    """
    accountOperator: AccountOperator
    annualPremium: Float
    applicableVacancyRate: Float
    calculatedNationalInsuranceBasicAmount: Float
    contractNavigation: ContractNavigation
    """
    Contract number in USB
    """
    contractNumber: String
    """
    Contract type in USB
    """
    contractType: String
    coverages: Coverages
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    employedDate: String
    employer: Employer
    """
    The amount the employer saves yearly for the customer. The amount is transfered to the customers selected pension provider
    """
    employerAnnualSavingsAmount: Float
    employmentPercentage: Float
    epkFleksibelContractId: ID
    id: ID
    pensionQualifyingIncome: Float
    pensionSchemeInclusionDate: String
    """
    Customer number in USB
    """
    personCustomerNumber: String
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    pkrEpkId: String
    productArea: String
    """
    Product code in USB
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    renewalDetails: RenewalDetail
    retirementAge: Int
    salary: Float
    salaryHistory: [SalaryHistory!]
    savingsRate: SavingsRate
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
    usbTermDuration: UsbTermDuration
  }

  """
  ## Egen pensjonskonto fleksibel/Egen pensjonskonto selvalgt(Before the rename)

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type EpkFleksibelContract implements Contract {
    """
    Account id in ProLink
    """
    accountId: String
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    coverages: Coverages
    """
    Date for first deposit in prolink
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    epkEmploymentContract: EpkEmploymentContract
    fromUsbContract: FromUsbContract
    historicMarketValues: [HistoricMarketValue!]
      @deprecated(reason: "This field is depracated, use portfolioInsight.accounts.marketValues[]")
    holdings: Holding
    """
    ID is on the format {participantId}-{accountId}:PL_A.
    """
    id: ID
    investmentProfile: [InvestmentProfile!]
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    pkrEpkId: String
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    reductionFund: ReductionFund
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use KeyValues instead")
    roles: [LinkRole!]
    selectableFunds: [SelectableFund!]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    usbCalculation: UsbCalculation
    """
    Contract number in USB
    """
    usbContractNumber: String
    """
    Contract type in USB
    """
    usbContractType: String
    """
    Customer number in USB
    """
    usbPersonCustomerNumber: String
    """
    Product code in USB
    """
    usbProductCode: String
  }

  """
  ## Egen pensjonskonto fleksibel pkb

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type EpkFleksibelPkbContract implements Contract {
    """
    Account id in ProLink
    """
    accountId: String
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    coverages: Coverages
    """
    Date for first deposit in prolink
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    fromUsbContract: FromUsbContract
    historicMarketValues: [HistoricMarketValue!]
      @deprecated(reason: "This field is depracated, use portfolioInsight.accounts.marketValues[]")
    holdings: Holding
    """
    ID is on the format {participantId}-{accountId}:PL_A.
    """
    id: ID
    investmentProfile: [InvestmentProfile!]
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    pkrEpkId: String
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile
    """
    redistributionProfile: [RedistributionProfile!]
    reductionFund: ReductionFund
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use KeyValues instead")
    roles: [LinkRole!]
    selectableFunds: [SelectableFund!]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
    """
    Contract number in USB
    """
    usbContractNumber: String
    """
    Contract type in USB
    """
    usbContractType: String
    """
    Customer number in USB
    """
    usbPersonCustomerNumber: String
    """
    Product code in USB
    """
    usbProductCode: String
  }

  type EpkSplit {
    activeMarketValue: Amount
    passiveMarketValue: Amount
  }

  """
  ## Fripolise med investeringsvalg

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type FmiContract implements Contract {
    """
    Account id in ProLink
    """
    accountId: String
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    compressionLimits: [Float!]!
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    coverages: Coverages
    """
    Date for first deposit in prolink
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    fromUsbContract: FromUsbContract
    historicMarketValues: [HistoricMarketValue!]
      @deprecated(reason: "This field is depracated, use portfolioInsight.accounts.marketValues[]")
    holdings: Holding
    id: ID
    investmentProfile: [InvestmentProfile!]
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    reductionFund: ReductionFund
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use KeyValues instead")
    roles: [LinkRole!]
    selectableFunds: [SelectableFund!]
    selectableInvestmentProfileInPayoutPeriod: [InvestmentProfileInPayoutPeriod]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
    """
    Contract number in USB
    """
    usbContractNumber: String
    """
    Contract type in USB
    """
    usbContractType: String
    """
    Customer number in USB
    """
    usbPersonCustomerNumber: String
    """
    Product code in USB
    """
    usbProductCode: String
  }

  """
  # Fripolise

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type FripoliseContract implements Contract {
    annualPremium: Float
    canBeConvertedToFmi: Boolean
    compressionLimits: [Float!]!
    contractNavigation: ContractNavigation
    """
    Contract number in USB
    """
    contractNumber: String
    """
    Contract type in USB
    """
    contractType: String
    coverages: Coverages
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    employer: Employer
    fromUsbContract: FromUsbContract
    id: ID
    """
    Customer number in USB
    """
    personCustomerNumber: String
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    productArea: String
    """
    Product code in USB
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
    usbTermDuration: UsbTermDuration
  }

  type FromUsbContract {
    fromContractNumber: Int
    fromContractType: String
    fromProductCode: String
  }

  input FullWithdrawal {
    fromDate: String
    """
    When the customer will do a full withdrawal from pension but still wants to continue working.
    This should be annual salary after full withdrawal. Defaults to zero if not provided
    Should be calculated by the client. Often this would not be the full time salary when the customer works less than 100%
    """
    salaryAfterFullWithdrawal: Int
  }

  input FullWithdrawalInput {
    """
    End age + month offset is optional. Is currently ignored for usb-products, where you can only set start date. If date is invalid(too early, or in violation of business rules) date is ignored and set to date registered on the contract.
    """
    endAge: Int
    endMonthOffset: Int
    startAge: Int!
    startMonthOffset: Int!
  }

  type Fund {
    amount: Amount
    """
    The isin of the fund
    """
    isin: String
    """
    The name of the fund
    """
    name: String
    percent: Float
  }

  type FundHoldings {
    costPrice: CostPrice
    isin: String
    marketValue: MarketValue
    name: String
    nav: NetAssetValue
    units: Float
    unrealizedProfit: UnrealizedProfit
    unrealizedProfitInPercent: Float
  }

  type FundHoldingsTotal {
    costPrice: CostPrice
    marketValue: MarketValue
    unrealizedProfit: UnrealizedProfit
    unrealizedProfitInPercent: Float
  }

  input FundOpenPrognosisInput {
    birthYear: Int
    gender: String
    marketValue: Float
    monthsOffset: Int
    payoutDurationYears: Int
    periodicDeposit: Int
    startPayoutAge: Int
  }

  type FundPerformance {
    last1Day: Float
    last1Month: Float
    last3Months: Float
    last6Months: Float
    last7Days: Float
    last12Months: Float
    last24Months: Float
    last36Months: Float
    last48Months: Float
    last60Months: Float
    last120Months: Float
    sinceStart: Float
    yearToDate: Float
  }

  input FundProfile {
    categoryId: String
    isin: String!
    marketValueShare: Float
    profileShare: Float
    targetPercentage: Float
  }

  """
  Description: TODO
  Online/ADS: online warning
  Scopes required: Realm storebrand - storebrand.life.private
  """
  type HistoricMarketValue {
    dateOfValue: String
    value: Float
  }

  type Holding {
    cash: Amount
    epkSplit: EpkSplit
    funds: [PortfolioInsightFund!]
  }

  """
  # Hybrid med garanti

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type HybridMedGarantiContract implements Contract {
    annualPremium: Float
    applicableVacancyRate: Float
    calculatedNationalInsuranceBasicAmount: Float
    contractNavigation: ContractNavigation
    """
    Contract number in USB
    """
    contractNumber: String
    """
    Contract type in USB
    """
    contractType: String
    coverages: Coverages
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    employedDate: String
    employer: Employer
    """
    The amount the employer saves yearly for the customer. The amount is invested into a guarantee
    """
    employerAnnualSavingsAmount: Float
    employmentPercentage: Float
    id: ID
    pensionQualifyingIncome: Float
    pensionSchemeInclusionDate: String
    """
    Customer number in USB
    """
    personCustomerNumber: String
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    productArea: String
    """
    Product code in USB
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    renewalDetails: RenewalDetail
    retirementAge: Int
    salary: Float
    salaryHistory: [SalaryHistory!]
    savingsRate: SavingsRate
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
    usbTermDuration: UsbTermDuration
  }

  """
  ## Hybrid med investeringsvalg

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type HybridMedInvesteringsvalgContract implements Contract {
    """
    Account id in ProLink
    """
    accountId: String
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    annualPremium: Float
    applicableVacancyRate: Float
    calculatedNationalInsuranceBasicAmount: Float
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    coverages: Coverages
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    employedDate: String
    employer: Employer
    """
    The amount the employer saves yearly for the customer. The amount is invested according to the distribution percentage in investmentProfile
    """
    employerAnnualSavingsAmount: Float
    employmentPercentage: Float
    historicMarketValues: [HistoricMarketValue!]
      @deprecated(reason: "This field is depracated, use portfolioInsight.accounts.marketValues[]")
    holdings: Holding
    """
    ID is on the format {participantId}-{accountId}:PL_A.
    """
    id: ID
    investmentProfile: [InvestmentProfile!]
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    pensionQualifyingIncome: Float
    pensionSchemeInclusionDate: String
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    reductionFund: ReductionFund
    renewalDetails: RenewalDetail
    retirementAge: Int
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use KeyValues instead")
    roles: [LinkRole!]
    salary: Float
    salaryHistory: [SalaryHistory!]
    savingsRate: SavingsRate
    selectableFunds: [SelectableFund!]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
    """
    Contract number in USB
    """
    usbContractNumber: String
    """
    Contract type in USB
    """
    usbContractType: String
    """
    Customer number in USB
    """
    usbPersonCustomerNumber: String
    """
    Product code in USB
    """
    usbProductCode: String
    usbTermDuration: UsbTermDuration
  }

  """
  # Pensjonsbevis from hybrid

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type HybridPensjonsbevisContract implements Contract {
    contractNavigation: ContractNavigation
    """
    Contract number in USB
    """
    contractNumber: String
    """
    Contract type in USB
    """
    contractType: String
    coverages: Coverages
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    employer: Employer
    fromUsbContract: FromUsbContract
    id: ID
    """
    Customer number in USB
    """
    personCustomerNumber: String
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    productArea: String
    """
    Product code in USB
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
  }

  """
  A date string in ISO8601 format: YYYY-MM-DD
  """
  scalar ISO8601Date

  type InsuranceCoverage {
    annualAmount: Float
    coverageName: CoverageName
    coverageTypeCode: String
    fromAndIncludingAge: Int
    lifelongPayout: Boolean
    toNotIncludingAge: Int
  }

  type InvestmentProfile {
    distributionPercentage: Float
    isin: String
    name: String
  }

  """
  This property is related to FMI contracts and is used when running prognosis and creating new settlement.
  Default choice is GUARANTEE.
  """
  enum InvestmentProfileInPayoutPeriod {
    GUARANTEE
    INVESTMENTS
  }

  input IpsOpenPrognosisInput {
    admFeeCode: String!
    birthDate: String!
    contractNumber: String!
    fundProfiles: [FundProfile!]
    gender: String!
    inflation: Boolean!
    lifelongReduction: Boolean!
    periodicDeposit: Float!
    singleDeposit: Float!
    startPayoutAge: Int!
  }

  type KeyValues {
    annualAverageReturn: Amount
    annualAverageReturnPct: Float
    lastQueryYear: Int
    marketValue: Amount
    paidIn: Amount
    paidOut: Amount
    totalDepositLastQueryYear: Amount
    totalReturn: Amount
    totalReturnPct: Float
  }

  enum KronAccountType {
    EPKS_DISCRETIONARY_MANAGEMENT
    IPS_DISCRETIONARY_MANAGEMENT
    PKB_DISCRETIONARY_MANAGEMENT
    SAVING_COMPANY_DISCRETIONARY_MANAGEMENT
    SAVING_COMPANY_ORDER_EXECUTION
    SAVING_DISCRETIONARY_MANAGEMENT
    SAVING_ORDER_EXECUTION
  }

  type KronContract {
    accountType: KronAccountType
    emoji: String
    id: ID
    label: String
    link: String
    marketValue: Float
  }

  type LifeBankAccountNumber {
    accountNumber: String
    """
    Field reflecting systemområde from UBW, available values are HOV (main),  SAL, UBE, PRO or empty.
    """
    area: String
  }

  type LifePayoutTransaction {
    amount: TwoDecimalAmount
    contractNumber: String
    dateFrom: String
    dateTo: String
    description: String
    payoutAccount: String
    payoutArea: PayoutArea
    payoutDate: String
    salaryCode: String
    transactionNumber: String
  }

  type LifeTaxInformation {
    percentage: String
    tableNumber: String
    taxCode: String
    taxCodeText: String
    year: Int
  }

  type LifeVoucher {
    vouchersByArea: [VouchersByArea!]
  }

  """
  ## Link Contracts

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type LinkContract implements Contract & LinkContractAbstract {
    """
    Account id in ProLink
    """
    accountId: String
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    beneficiaries: [Beneficiary!]
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    """
    When the contract was "trådt i kraft". Will be 1980-01-01 until the first investment in the funds.
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    """
    The KID number customer can use to transfer money from their own bank account to the ProLink contract.
    Money transferred will be invested according setting of the product propeties to the contract's product code.
    The three options are 0. "Don't invest" 1. "Invest according to the current holdings profile" 2. "Invest according to the investment profile"
    The current choice for the products in ProLink is 2. Invest according to the investment profile
    """
    fixedKid: String
    historicMarketValues: [HistoricMarketValue!]
      @deprecated(reason: "This field is depracated, use portfolioInsight.accounts.marketValues[]")
    holdings: Holding
    id: ID
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    The bank account number in ProLink used when doing one time payments using the fixed kid
    """
    oneTimeDepositAccountNumber: String
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile
    """
    redistributionProfile: [RedistributionProfile!]
    reductionFund: ReductionFund
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use KeyValues instead")
    roles: [LinkRole!]
    """
    Savings agreement or the the periodic deposits that customer does
    """
    savingsAgreements: [SavingsAgreement!]
    selectableFunds: [SelectableFund!]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    specialRetirementAge: Boolean
    """
    trade account number in ProLink
    """
    tradeAccountSell: String
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
  }

  """
  ## Link Contract Interface
  """
  interface LinkContractAbstract implements Contract {
    """
    Account id in ProLink
    """
    accountId: String
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    beneficiaries: [Beneficiary!]
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    """
    When the contract was "trådt i kraft". Will be 1980-01-01 until the first investment in the funds.
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    """
    ID is on the format {participantId}-{accountId}:PL_A.
    """
    id: ID
    managers: [Manager!]
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile
    """
    redistributionProfile: [RedistributionProfile!]
    reductionFund: ReductionFund
    roles: [LinkRole!]
    """
    Savings agreement or the the periodic deposits that customer does
    """
    savingsAgreements: [SavingsAgreement!]
    seller: LinkSeller
    """
    trade account number in ProLink
    """
    tradeAccountSell: String
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
  }

  """
  ## Link Corporate Contracts

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  """
  type LinkOrganizationContract implements Contract & LinkContractAbstract {
    """
    Account id in ProLink
    """
    accountId: String
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    beneficiaries: [Beneficiary!]
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    """
    When the contract was "trådt i kraft". Will be 1980-01-01 until the first investment in the funds.
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    holdings: Holding
    """
    ID is on the format {participantId}-{accountId}:PL_A.
    """
    id: ID
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    The bank account number in ProLink used when doing one time payments using the fixed kid
    """
    oneTimeDepositAccountNumber: String
    organization: Organization
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile
    """
    redistributionProfile: [RedistributionProfile!]
    reductionFund: ReductionFund
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use KeyValues instead")
    roles: [LinkRole!]
    """
    Savings agreement or the the periodic deposits that customer does
    """
    savingsAgreements: [SavingsAgreement!]
    selectableFunds: [SelectableFund!]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    """
    trade account number in ProLink
    """
    tradeAccountSell: String
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
  }

  """
  The payout period registered in ProLink
  """
  type LinkPayout {
    endDate: ISO8601Date
    fromDate: ISO8601Date
    lifeLongPayout: Boolean
  }

  type LinkRole {
    """
    The customer number for the name. nin if it is an customer. organization number if organization. Will only be returned for internal users
    """
    customerNumber: String
    """
    The name of the person or organization
    """
    name: String
    """
    the roles participant id in ProLink
    """
    participantId: String
    """
    The type of role
    """
    roleType: LinkRoleType
  }

  """
  INSURED = forsikrede
  POLICY_HOLDER = forsikringstager
  """
  enum LinkRoleType {
    INSURED
    POLICY_HOLDER
  }

  """
  Will only be returned if it is an advisor. Customers will never see this field
  """
  type LinkSeller {
    """
    The customer number for the name. nin if it is an advisor. Organization number if organization. Will not be return if is a nin
    """
    customerNumber: String
    """
    The name of the person or organization
    """
    name: String
    """
    The sellers participant id in ProLink
    """
    participantId: String
  }

  type Manager {
    """
    The expiration date for the manager type
    """
    expirationDate: ISO8601Date
    """
    The first name of the manager
    """
    firstName: String
    """
    The last name of the manager
    """
    lastName: String
    """
    The code for the manager role
    """
    managerRoleCode: String
  }

  type MarketValue {
    amount: Float
    currency: String
  }

  enum MartialStatusEnum {
    MARRIED
    UNMARRIED
  }

  type NavTransaction {
    amount: Amount
    date: ISO8601Date
  }

  type NetAssetValue {
    currency: String
    date: String
    value: Float
  }

  """
  # Norskpensjon

  ### Consents
  All calls to norskpensjon must have an active consent from the customer before calling

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - corporate.internal
  """
  type Norskpensjon {
    afp: NorskpensjonContract @deprecated(reason: "Use afpPrognosis instead")
    afpPrognosis: Prognosis
    contracts: [NorskpensjonContract!]
    folketrygdPrognosis: Prognosis
    unavailablePensionProviders: [UnavailablePensionProvider!]
  }

  """
  # Norskpensjon Contract

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - corporate.internal
  """
  type NorskpensjonContract {
    """
    The category the product can have in norskpensjon. Is subject to be changed.
    Values can be: individuelleOrdninger, privatAFP, privatTjenestepensjon, offentligTjenestepensjon and folketrygd
    """
    category: String
    """
    Description when grading is not allowed. Values comes direclty from norskpensjon and known values are IKKE_STOTTET and IKKE_TILLATT.

    IKKE_STOTTET: If the combinations of partial withdrawal and when is not allowd
    IKKE_TILLATT: if the agreement's regulations doesn't allow for partial withdrawal
    """
    causeLackingGrading: String
    """
    Description when prognosis failed for some reason. Values comes direclty from norskpensjon and known values is UKJENT_PRODUKTTYPE

    UKJENT_PRODUKTTYPE contract has a invalid combinatin of category and sub category
    """
    causeNotCalculated: String
    """
    The contract number form the pension provider. May not be unique and can be null
    """
    contractNumber: String
    """
    The employer when available
    """
    employer: Employer
    """
    Value from norskpensjon. Can be empty. If start age is present then endAge is null then it means lifelong
    """
    endAge: Int
    """
    Will not contain the funds for norskpensjon
    """
    holdings: NorskpensjonHoldings
    """
    A note
    """
    note: String
    """
    The pension provider when available
    """
    pensionProvider: PensionProvider
    productArea: String
    """
    The product description. Can by anything and is controlled by norskpensjon
    """
    productDescription: String
    prognosis: Prognosis
    """
    Generated ID based on the content of the contract, might be non unique. Must be comibned with the input if to be used as cache key.
    """
    softId: ID
    """
    Value from norskpensjon. Can be empty
    """
    startAge: Int
    """
    The sub category the product can have in norskpensjon. Can be empty
    Values can be: fripolise, foreningskollektiv, ipa, ips, kapitalforsikring, kollektiv livrente, individuell livrente, foretakspensjon, innskuddspensjon, fortsettelsesforsikring, fripolisemedinvesteringsvalg, pensjonsbevis, oppsatt pensjon
    """
    subCategory: String
  }

  """
  Description: TODO
  Online/ADS: online warning
  Scopes required: Realm storebrand - storebrand.life.private
  """
  type NorskpensjonHoldings {
    """
    Only available for EPK and EPK selvvalgt
    """
    activeMarketValue: Float
    """
    Only available for EPK and EPK selvvalgt
    """
    passiveMarketValue: Float
    totalMarketValue: Float
  }

  input NorskpensjonInput {
    simulationParameters: NorskpensjonSimulationInput
  }

  input NorskpensjonSimulationInput {
    hasAfp: Boolean
    martialStatus: MartialStatusEnum
    """
    Will the customer parther have an income over 2G(base-amount)
    """
    partnerHasIncomeOver2G: Boolean
    """
    Will the customer's partner receive pension
    """
    partnerHasPension: Boolean
    withdrawalPeriods: WithdrawalPeriods
  }

  type OfaVendor {
    isStorebrand: Boolean
    ofaVendorServiceTime: OfaVendorserviceTime
    tpNumber: Int
    vendorName: String
    yearsOfServiceTimePrognosis: Int
  }

  type OfaVendorserviceTime {
    days: Int
    months: Int
    years: Int
  }

  type OnBehalfOfCustomer {
    cmid: String
    firstName: String
    lastName: String
  }

  type OnBehalfOfEngagement {
    askAccounts(ids: [ID]): [UCITSAccountOnBehalfOfCustomer!]
    investorAccounts(ids: [ID]): [UCITSAccountOnBehalfOfCustomer!]
  }

  type OpenPrognosis {
    bank(bankOpenPrognosisInput: BankOpenPrognosisInput): Prognosis!
    fund(fundOpenPrognosisInput: FundOpenPrognosisInput): Prognosis!
    ips(ipsOpenPrognosisInput: IpsOpenPrognosisInput): Prognosis!
  }

  type Organization {
    name: String
    organizationNumber: String
  }

  type OrganizationEngagement {
    """
    avlosningspensjonContracts are only relevant for organizations
    """
    avlosningspensjonContracts(ids: [String]): [LinkOrganizationContract!]
    bankAccounts(ids: [ID], productCodes: [String]): [BankAccount!]
    ekstrapensjonContracts(ids: [String]): [LinkOrganizationContract!]
    fondskontoLinkContracts(ids: [String]): [LinkOrganizationContract!]
    garantiContracts(ids: [String]): [LinkOrganizationContract!]
    investorAccounts(ids: [ID]): [UCITSOrganizationAccount!]
  }

  input PartialWithdrawal {
    fromDate: String
    """
    salary in this period until fullwithdrawal date
    """
    salaryInPartialWithdrawalPeriod: Int
    withdrawalPercentage: Int
  }

  input PartialWithdrawalInput {
    partTimePercentage: Float
    startAge: Int
    startMonthOffset: Int
    withdrawalPercentage: Float
  }

  type Payer {
    id: String
    name: String
  }

  type Payout {
    deductionGroup: String
    deductionSetup: String
    paidAmount: TwoDecimalAmount
    paidDate: String
    paidMonth: Int
    paidYear: Int
    text: String
    textStripped: String
  }

  enum PayoutArea {
    COLLECTIVE
    PRIVATE
    PROLINK
  }

  type PayoutMethod {
    abroadTransmission: Boolean
    bankAccountNumber: String
    sendByMail: Boolean
  }

  type PayoutMonth {
    paidMonth: Int
    paidYear: Int
    payouts: [Payout!]
    totalOtherDeductions: Float
    totalPaidGrossAmount: Float
    totalPaidNetAmount: Float
    totalTax: Float
  }

  type PayoutPeriod {
    compressed: Boolean
    endAge: Int
    endDate: ISO8601Date
    endMonthOffset: Int
    lifeLong: Boolean
    monthlyAmount: Float
    notices: [TextElement]
    oneTimePayout: Boolean
    startAge: Int
    startDate: ISO8601Date
    startMonthOffset: Int
    yearlyAmount: Int
  }

  type PayoutPlan {
    age: Int
    amount: Float
    endDate: ISO8601Date
    """
    Number of months after the customers birthday the payout will end
    """
    endMonthOffset: Int
    maxAmount: Float
    minAmount: Float
    startDate: ISO8601Date
    """
    Number of months after the customers birthday the payout starts
    """
    startMonthOffset: Int
  }

  type PayoutYear {
    paidYear: Int
    payoutMonths: [PayoutMonth!]
  }

  type PensionFundHolding {
    actualDistribution: Float
    isin: String
    marketValue: Amount
    name: String
    nav: NavTransaction
    numberOfShares: Float
  }

  type PensionFundHoldings {
    funds: [PensionFundHolding!]
    sumDeposit: Amount
    totalMarketValue: Amount
    totalReturn: Amount
    yearlyDeposit: Amount
  }

  """
  # PensionFundItpContract
  "Innskuddsordninger" from Storebrand pensjonstjenester AS/Abakus

  #### OIDC scopes
  * Realm storebrand  - storebrand.pensionfunds.private
  * Realm corporate   - corporate.internal
  """
  type PensionFundItpContract implements Contract {
    contractNavigation: ContractNavigation
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The employer or the pension fund
    """
    employer: Employer
    """
    if the contract is linked to an epk-contract, it will be available here.
    """
    epkContract: EpkContract
    """
    the holdigns for the contract
    """
    holdings: PensionFundHoldings
    """
    The id for the contract
    """
    id: ID
    """
    If the scheme is a pension capital certificate/pensjonskapitalbevis/pkb
    """
    pensionCapitalCertificate: Boolean
    pensionFundCompanyName: String
    productArea: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    The default retirementAge for the contract
    """
    retirementAge: Int
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
  }

  type PensionFundPayoutOrganization {
    organizationName: String
    organizationNumber: String
    payoutYears: [PayoutYear!]
  }

  """
  # PensionFundYtpUnderPayoutContract - ytelsepensjon under utbetaling

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type PensionFundUnfundedYtpUnderPayoutContract implements Contract {
    contractNavigation: ContractNavigation
    """
    Contract number in USB
    """
    contractNumber: String
    """
    Contract type in USB
    """
    contractType: String
    coverages: Coverages
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    employedDate: String
    employer: Employer
    employmentPercentage: Float
    id: ID
    pensionSchemeInclusionDate: String
    """
    Customer number in USB
    """
    personCustomerNumber: String
    productArea: String
    """
    Product code in USB
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    salary: Float
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
  }

  """
  # PensionFundYtpContract
  "Ytelsesordninger" from Storebrand pensjonstjenester AS/Abakus

  #### OIDC scopes
  * Realm storebrand  - storebrand.pensionfunds.private
  * Realm corporate   - corporate.internal
  """
  type PensionFundYtpContract implements Contract {
    contractNavigation: ContractNavigation
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The employer or the pension fund
    """
    employer: Employer
    """
    The id for the contract
    """
    id: ID
    """
    If the scheme is paid up policy/fripolise
    """
    paidUpPolicy: Boolean
    pensionFundCompanyName: String
    productArea: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    The default retirementAge for the contract
    """
    retirementAge: Int
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
  }

  type PensionFundsVoucher {
    organizations: [PensionFundPayoutOrganization!]
  }

  """
  Online
  Fetches payout information for life and pensionfunds
  """
  type PensionPayouts {
    lifeBankAccountNumbers: [LifeBankAccountNumber!]
    lifePayouts: LifeVoucher
    lifeTaxInformation: [LifeTaxInformation!]
    pensionFundPayouts: PensionFundsVoucher
  }

  type PensionProvider {
    name: String
  }

  """
  Description:
  Online/ADS: ADS
  Scopes required: Realm storebrand - storebrand.life.private
  """
  type PeriodicDepositDetail {
    distributionPercentage: Float
    isin: String
    name: String
    periodicAmount: Float
    yearlyAmount: Float
  }

  """
  ## Pensjonskapitalbevis

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type PkbContract implements Contract {
    """
    Account id in ProLink
    """
    accountId: String
    accountOperator: AccountOperator
    administrationFeeCode: String
    """
    AS1 - Anbefalt sparing, LN0 - Freeze and life long reduction/"anbefealt pensjon",
    LN3 - life long reduction/"anbefalt pensjon", REBA1 - Rebalancing, STDNED1 - 10 year long step down, STDNED2 - 10 year long step down,
    STDNED3 - 10 year long step down, TOMAL - No life long reduction and no step down. Free choosing of funds
    """
    allocationCode: String
    contractNavigation: ContractNavigation
    """
    Contract number in ProLink
    """
    contractNumber: String
    coverages: Coverages
    """
    Date for first deposit in prolink
    """
    createdDate: String
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The name that customer has given
    """
    customerSuppliedContractName: String
    employer: Employer
    fromUsbContract: FromUsbContract
    historicMarketValues: [HistoricMarketValue!]
      @deprecated(reason: "This field is depracated, use portfolioInsight.accounts.marketValues[]")
    holdings: Holding
    """
    ID is on the format {participantId}-{accountId}:PL_A.
    """
    id: ID
    investmentProfile: [InvestmentProfile!]
    keyValues: KeyValues
    managers: [Manager!]
    marketValues: [PortfolioInsightMarketValue!]
    """
    Participant id in ProLink
    """
    participantId: String
    payout: LinkPayout
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    pkrPkbId: String
    productArea: String
    """
    Product code in ProLink
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    If customer is allocated in REBA1, The customer will on 1th. march redistribute their current holdings into the defined redistributionProfile
    """
    redistributionProfile: [RedistributionProfile!]
    reductionFund: ReductionFund
    returnOnInvestment: ReturnOnInvestment @deprecated(reason: "This field is deprecated, use KeyValues instead")
    roles: [LinkRole!]
    selectableFunds: [SelectableFund!]
    """
    The available profiles the customer can select from. The profiles (Combinations of the funds) are also being used in anbefalt pensjon
    """
    selectableProfiles: [SelectableProfile!]
    seller: LinkSeller
    specialRetirementAge: Boolean
    """
    true if the contract status indicates there exists an active payout
    """
    underPayout: Boolean
    usbCalculation: UsbCalculation
    """
    Contract number in USB
    """
    usbContractNumber: String
    """
    Contract type in USB
    """
    usbContractType: String
    """
    Customer number in USB
    """
    usbPersonCustomerNumber: String
    """
    Product code in USB
    """
    usbProductCode: String
  }

  type PortfolioInsight {
    accounts: [Account!]
    isExcludedFromProfitCalculation: Boolean
    """
    Total keyValues for all selected accounts
    """
    keyValues: KeyValues
    marketValues: [PortfolioInsightMarketValue!]
  }

  type PortfolioInsightFund {
    actualDistribution: Float
    fundHistory: [PortfolioInsightFundHistory]
    fundSheet: String
    gav: Float
    isin: String
    marketValue: Amount
    marketValueGuaranteePortfolioWithProfitToday: Amount
    name: String
    nav: NavTransaction
    netPayment: Amount
    numberOfShares: Float
    paidIn: Amount
    paidOut: Amount
    totalReturn: Amount
    totalReturnPct: Float
  }

  type PortfolioInsightFundHistory {
    dailyReturn: Amount
    dailyReturnPct: Float
    date: ISO8601Date
    gav: Float
    marketValue: Amount
    nav: NavTransaction
    netPayment: Amount
    numberOfShares: Float
    paidIn: Amount
    paidOut: Amount
    totalReturn: Amount
    totalReturnPct: Float
  }

  type PortfolioInsightMarketValue {
    accumulatedPaidIn: Amount
    accumulatedPaidOut: Amount
    date: ISO8601Date
    mainCapital: Amount
    marketValue: Amount
  }

  type PortfolioInsightTransaction {
    amountInTradeCurrency: Amount
    description: Description
    isin: String
    name: String
    nav: NavTransaction
    numberOfShares: Float
    payer: Payer
    tradeDate: ISO8601Date
    transactionCode: String
    transactionId: String
  }

  enum PortfolioQueryField {
    ASSET_IDS
    FUND_DETAILS
    HOLDINGS
    KEY_VALUES
    KEY_VALUES_TOTAL
    MARKET_VALUES
    MARKET_VALUES_TOTAL
    TRANSACTIONS
  }

  type ProductLevel {
    productLevelCode: String
    productLevelName: String
  }

  type ProductName {
    english: String
    norwegian: String
  }

  type Prognosis {
    accumulatedBalance: [AccumulatedBalance!]
    additionalDataPrognosis: AdditionalDataPrognosis
    compressed: Boolean
    contractId: String
    id: String
    lifeLong: Boolean
    oneTimePayout: Boolean
    payoutPeriods: [PayoutPeriod]
    payoutPlan: [PayoutPlan!]
    prognosisMetaInformation: PrognosisMetaInformation
    simulationStatus: SimulationStatus
    strapp: String
    withdrawalAllowed: Boolean
    xCorrelationId: String
  }

  input PrognosisInput {
    id: String!
    parameters: PrognosisParametersInput!
  }

  type PrognosisMetaInformation {
    compressionLimit: Float
    """
    will be returned true if withdrawal can be created automaticalle. Has currently only been tested 100% for EPK.
    Use with caution
    """
    defaultPayoutDuration: Boolean
    payoutEndDate: ISO8601Date
    payoutStartDate: ISO8601Date
    savingsEndDate: ISO8601Date
    savingsStartDate: ISO8601Date
  }

  input PrognosisParametersInput {
    """
    Compression limit can be set for FMI, YTP and Fripolise contracts
    """
    compressionLimit: Float
    fullWithdrawal: FullWithdrawalInput!
    investmentProfileInPayoutPeriod: InvestmentProfileInPayoutPeriod
    partialWithdrawal: PartialWithdrawalInput
  }

  type PublicPension {
    contracts(ids: [ID]): [PublicPensionContract] @deprecated(reason: "Use 'publicPensionContract' for contracts")
    publicPensionPrognosis: Prognosis @deprecated(reason: "Use 'publicPensionPrognosis' for prognosis")
  }

  type PublicPensionActions {
    simulatePublicPension: Boolean
  }

  """
  # PublicPension

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - corporate.internal
  """
  type PublicPensionContract implements Contract {
    actions: PublicPensionActions
      @deprecated(reason: "Use 'hasPublicPensionEngagements' found int PublicPensionPrognosisParameters")
    applicableVacancyRate: Float
    averagePartTimePercentage: Float
    calculatedNationalInsuranceBasicAmount: Float
    contractNavigation: ContractNavigation
    """
    Contract number in USB
    """
    contractNumber: String
    coverages: Coverages
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    """
    The employement date
    """
    employedDate: String
    """
    Number of months the customer has been working for the employer.
    Might be less than the duration of employedDate and resignationDate due to gap in the working history (leave, sickness, etc)
    """
    employedTimeInMonths: Int
    """
    The employer
    """
    employer: Employer
    """
    The first retirement age when the customer can retire. Default is 67, but some customers may have 58, 60 when special retirement ages
    """
    firstRetirementAge: Int
    id: ID
    pensionQualifyingIncome: Float
    pensionSchemeInclusionDate: String
    """
    Customer number in USB
    """
    personCustomerNumber: String
    """
    Position Age - Stillingsalder
    """
    positionAge: Int
    productArea: String
    """
    Product code in USB
    """
    productCode: String
    """
    Product name in USB
    """
    productName: ProductName
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    """
    When the employee retired from the employer
    """
    resignationDate: String
    retirementAge: Int
    """
    Work and Salary Details
    """
    salaryHistory: [SalaryHistory!]
    status: PublicPensionContractStatus
  }

  enum PublicPensionContractStatus {
    ACTIVE
    EARNED_PENSION_RIGHT
    PAYOUT
  }

  """
  How many years the employee have worked in public sector for agreements outside Storebrand/other pension providers.
  The prognosis handles everything related to service time on contracts in Storebrand
  """
  input PublicPensionInput {
    serviceTimeBefore2011FromOtherProviders: Int
    serviceTimeFromOtherProviders: Int
  }

  type PublicPensionPrognosis {
    currentWithdrawalDate: ISO8601Date
    id: String
    paymentPlanAfp62: [PayoutPlan!]
    paymentPlanAfp65: [PayoutPlan!]
    paymentPlanFolketrygd: [PayoutPlan!]
    paymentPlanPension: [PayoutPlan!]
    paymentPlanSalary: [PayoutPlan!]
    """
    List of statuses regarding the customer public pension, and whether prognosis can be performed
    """
    statusMessages: [PublicPensionStatusMessage!]
  }

  input PublicPensionPrognosisInput {
    firstWithdrawalAge: Int
    firstWithdrawalMonthOffset: Int
    futureSalary: Int
    lastWithdrawalAge: Int
    lastWithdrawalMonthOffset: Int
    withdrawalPercentage: Int
    workPercentage: Float
    yearsAddedBefore2011: Int
    yearsAddedBefore2020: Int
  }

  type PublicPensionPrognosisParameters {
    """
    The customers added salary
    """
    addedSalary: Int
    """
    True if the customer should be asked to input service time from oter providers
    """
    canChangeFutureSalary: Boolean
    """
    True if the customer should be allowed to do flexible withdrawal
    """
    canChangeWithdrawalPercentage: Boolean
    """
    True if the customer should be allowed to change their working percentage for the prognosis
    """
    canChangeWorkingPercentage: Boolean
    """
    The current working percentage for the customer
    """
    currentWorkingPercentage: Float
    """
    If no firstWithdrawalDate is provided when doing public pension prognosis, this is the one that will be used
    """
    defaultWithdrawalDate: ISO8601Date
    """
    True of the customer has an earned pension right
    """
    earnedPensionRight: Boolean
    """
    False of the customer does not have any public pension engagements. If false -> do not ask for public pension prognosis
    """
    hasPublicPensionEngagements: Boolean
    """
    The customers main salary
    """
    mainSalary: Int
    """
    The latest age for which the customer can perform the prognosis
    """
    maximumAgeWithdrawal: Int
    """
    The earliest age for which the customer can perform the prognosis
    """
    minimumAgeWithdrawal: Int
    """
    List of vendors that we were able to fetch service time from
    """
    ofaVendors: [OfaVendor!]
    """
    The registered retirement age for the customer
    """
    retireAge: Int
    """
    True if the customer should be asked to input service time from oter providers
    """
    showAddedYears: Boolean
    """
    List of statuses regarding the customer public pension, and whether prognosis can be performed
    """
    statusMessages: [PublicPensionStatusMessage!]
    """
    years of service time from ofa that will be used for prognosis
    """
    yearsOfServiceTimeOfa: Int
    """
    years of service time before 2011 from ofa that will be used for prognosis
    """
    yearsOfServiceTimeOfaBefore2011: Int
    """
    years of service time before 2020 from ofa that will be used for prognosis
    """
    yearsOfServiceTimeOfaBefore2020: Int
  }

  input PublicPensionPrognosisParametersInput {
    firstWithdrawalAge: Int
    firstWithdrawalMonthOffset: Int
  }

  type PublicPensionStatusMessage {
    """
    Uniquely identifying. Should be used for text-lookup in FMS
    """
    messageKey: String
    """
    Norwegian text describing the status message. Primarily for developers and debugging. Should not be exposed to the customer
    """
    messageText: String
    """
    A - warning: Can display perform and show prognosis. Should show message
    S - stop: Unable to perform prognosis. Should show message
    F - failed: Internal Error for savings-rest-api
    """
    messageType: String
  }

  """
  Root query element
  """
  type Query {
    baseAmount: BaseAmount
    norskpensjon(input: NorskpensjonInput): Norskpensjon
    onBehalfOfEngagement: OnBehalfOfEngagement!
    openPrognosis: OpenPrognosis!
    organizationEngagement: OrganizationEngagement!
    pensionPayouts: PensionPayouts
    """
    ids: Storebrand uniqueID
    """
    portfolioInsight(fromDate: ISO8601Date, ids: [String], toDate: ISO8601Date): PortfolioInsight!
    prognosis(input: PrognosisInput): Prognosis
    publicPensionPrognosis(input: PublicPensionPrognosisInput): PublicPensionPrognosis
    publicPensionPrognosisParameters(input: PublicPensionPrognosisParametersInput): PublicPensionPrognosisParameters
    savingsContracts(ids: [String]): [Contract]
    savingsEngagement(input: QueryInput): SavingsEngagement!
  }

  input QueryInput {
    test: String
  }

  type Rate {
    bottomRateG: Float
    bottomSalary: Float
    savingsRatePercent: Float
    topRateG: Float
    topSalary: Float
  }

  """
  The fund
  """
  type RedistributionProfile {
    distributionPercentage: Float
    isin: String
    name: String
  }

  """
  The target reduction fund if the customer has chosen to be allocated in STDNED1, STDNED3
  """
  type ReductionFund {
    isin: String
    name: String
  }

  type RenewalDetail {
    renewalDate: String
    renewalTxt: String
  }

  """
  Valid retirement date for birthday at a given age. Will be set to the first in each month ignoring the day of the month.
  After 62 the first avaible date will be the next month and the first day of the month.
  Range is usually [62-75!]
  """
  type RetirementAge {
    age: Int
    date: String
    """
    How many months after the customers birthday, at the given age retirement should start. 0 indicates the same month as the customers birthday.
    Example: A customer with birthday 1990-05-16 gives an offset of 1. June will then be the selected month for retirement. If the same customer gives an offset og 2, July will be used.
    """
    monthOffset: Int
  }

  """
  Description: TODO
  Online/ADS: online warning
  Scopes required: Realm storebrand - storebrand.life.private
  """
  type ReturnOnInvestment {
    paidIn: Float
    paidOut: Float
    profit: Float
    profitPercent: Float
    totalMarketValue: Float
  }

  type Rights {
    createdDateTime: String
    type: String
    validToDate: String
  }

  type SalaryHistory {
    """
    This is overtime, bonus paid extra - Tilleggslønn
    """
    additionalSalary: Int
    """
    This is base salary for given period - Grunnlønn
    """
    baseSalary: Int
    """
    iso-date-string 2021-10-12
    """
    endDate: String
    """
    iso-date-string 2021-10-12
    """
    fromDate: String
    partTimePercent: Float
    """
    This is total salary (base salary + additional salary)
    """
    totalSalary: Int
  }

  """
  Description:
  Online/ADS: ADS
  Scopes required: Realm storebrand - storebrand.life.private
  """
  type SavingsAgreement {
    """
    Which day in the month
    """
    dayInMonth: Int
    """
    How much the periodic amount on the savings agreement will increase on the nextIndexingDate
    """
    indexingRatePercent: Float
    """
    The savings agreement's kid number. Will match with the customers avtale giro in their bank account number
    """
    kid: String
    """
    The date when the indexingRatePercent will increase
    """
    nextIndexingDate: ISO8601Date
    """
    1 = one saving each year, 2 = half year . 4 = quartly, 6 = every other month,  12 = monthly
    """
    numberOfPeriodsAYear: Int
    """
    The saving agreement's order number
    """
    orderNumber: String
    """
    The amount paid in the at interval of the number of periods a year
    """
    periodicAmount: Float
    """
    How the periodic amount is distributed in each of the funds
    """
    periodicDepositDetails: [PeriodicDepositDetail!]
    """
    The status of the savings agreement. O = active, V = Awaiting confirmation&not signed at NETS
    """
    status: String
    """
    When the savings agreement is valid from
    """
    validFromDate: ISO8601Date
    """
    # When the savings agreement is valid to
    """
    validToDate: ISO8601Date
    """
    the yearly amount saved
    """
    yearlyAmount: Float
  }

  type SavingsEngagement {
    askAccounts(ids: [ID]): [UCITSAccount!]
    bankAccounts(ids: [ID], productCodes: [String]): [BankAccount!]
    ekstrapensjonContracts(ids: [ID]): [LinkContract!]
    ekstrapensjonEmploymentContracts(ids: [ID]): [EkstrapensjonEmploymentContract!]
    engagementFlag: EngagementFlag
    epkContracts(ids: [ID]): [EpkContract!]
    epkEmploymentContracts(ids: [ID]): [EpkEmploymentContract!]
    epkFleksibelContracts(ids: [ID]): [EpkFleksibelContract!]
    epkFleksibelPkbContracts(ids: [ID]): [EpkFleksibelPkbContract!]
    fmiContracts(ids: [ID]): [FmiContract!]
    fmiMedGarantiContracts(ids: [ID]): [FripoliseContract!]
    fondskontoLinkContracts(ids: [ID]): [LinkContract!]
    fripoliseContracts(ids: [ID]): [FripoliseContract!]
    garantiContracts(ids: [ID]): [LinkContract!]
    hybridMedGarantiContracts(ids: [ID]): [HybridMedGarantiContract!]
    hybridMedInvesteringsvalgContracts(ids: [ID]): [HybridMedInvesteringsvalgContract!]
    hybridPensjonsbevisContracts(ids: [ID]): [HybridPensjonsbevisContract!]
    innskuddspensjonMedGarantiContracts(ids: [ID]): [FripoliseContract!]
    investorAccounts(ids: [ID]): [UCITSAccount!]
    ipaAlisContracts(ids: [ID]): [AlisContract!]
    ipaLinkContracts(ids: [ID]): [LinkContract!]
    ipoContracts(ids: [ID]): [LinkContract!]
    ipsContracts(ids: [ID]): [LinkContract!]
    kapitalforsikringAlisContracts(ids: [ID]): [AlisContract!]
    kollektivLivrenteContracts(ids: [ID]): [LinkContract!]
    kronContracts(ids: [ID]): [KronContract!]
    livrenteAlisContracts(ids: [ID]): [AlisContract!]
    livrenteLinkContracts(ids: [ID]): [LinkContract!]
    pensionFundUnfundedItpContracts(ids: [ID]): [PensionFundItpContract!]
    pensionFundUnfundedYtpContracts(ids: [ID]): [PensionFundYtpContract!]
    pensionFundUnfundedYtpUnderPayoutContracts(ids: [ID]): [PensionFundUnfundedYtpUnderPayoutContract!]
    pensionFundYtpContracts(ids: [ID]): [PensionFundYtpContract!]
    pensionPayouts: PensionPayouts @deprecated(reason: "Use 'query.pensionPayouts'")
    pkbContracts(ids: [ID]): [PkbContract!]
    publicPension: PublicPension
      @deprecated(
        reason: "Use 'savingsEngagement.publicPensionContracts' for contracts. Use 'publicPensionPrognosis' for prognosis"
      )
    publicPensionContracts(ids: [ID]): [PublicPensionContract!]
    simulationParameters: SimulationParameters
    ytpContracts(ids: [ID]): [YtpContract!]
  }

  type SavingsRate {
    additional: Rate
    basic: Rate
  }

  """
  A fund  that the customer can select from according to the account
  """
  type SelectableFund {
    administrationFee: Float
    expectedReturnGross: Float
    expectedReturnNet: Float
    fundManagementFee: Float
    fundPerformance: FundPerformance
    isin: String
    name: String
    productSheetLink: String
    riskClass: Int
    stbSustainabilityScore: Int
  }

  """
  A fund/profile that the customer can select from
  """
  type SelectableProfile {
    isin: String
    name: String
    """
    The risk category L = Forsiktig, EF = Ekstra forsiktig, M = Balansert, H = Offensiv, EH = Ekstra Offensiv
    """
    riskCategory: String
  }

  type SimulationParameters {
    customerBirthDate: String
    retirementAges: [RetirementAge!]
  }

  input SimulationParametersInput {
    martialStatus: MartialStatusEnum
    """
    Will the customer parther have an income over 2G(base-amount)
    """
    partnerHasIncomeOver2G: Boolean
    """
    Will the customer's partner receive pension
    """
    partnerHasPension: Boolean
    publicPension: PublicPensionInput
    withdrawalPeriods: WithdrawalPeriods
  }

  type SimulationResult {
    results: [Prognosis!]
  }

  enum SimulationSeverity {
    BUSINESS_ERROR
    NOTICE
    NOT_ALLOWED
    OK
    SIMULATION_ERROR
  }

  type SimulationStatus {
    messageKey: String
    severity: SimulationSeverity
    simulationMessage: TextElement
  }

  enum SystemArea {
    PL_A
    SAM_A
  }

  type TextElement {
    english: String
    fmsKey: String
    norwegian: String
  }

  """
  A custom scalar that rounds the amount to two decimals and adds trailing zero's to numbers with
  less than two decimals. Adding trailing zero's is done to compensate for the default behaviour of
  most json serializers which is to truncate trailing zero's.
  """
  scalar TwoDecimalAmount

  """
  # UCITS Account

  #### OIDC scopes
  * Realm storebrand  - storebrand.funds.private
  * Realm corporate   - TODO
  """
  type UCITSAccount implements Contract & UCITSAccountAbstract {
    accountId: String
    accountName: String
    accountOperator: String
    accountType: String
    askTaxDetails: AskTaxDetails
    closedDate: String
    contractNavigation: ContractNavigation
    createdDate: String
    holdings: UCITSHoldings
    """
    ID is on the format {accountId}:SAM_A.
    """
    id: ID
    legalEntity: String
    pensionType: String
    productArea: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    rights: [Rights!]
    savingAgreements: [UCITSSavingAgreement!]
    seller: String
    withdrawalAgreements: [WithdrawalAgreement!]
  }

  """
  # UCITS Abstract Account

  #### OIDC scopes
  * Realm storebrand  - storebrand.funds.private
  * Realm corporate   - TODO
  """
  interface UCITSAccountAbstract implements Contract {
    accountId: String
    accountName: String
    accountOperator: String
    accountType: String
    askTaxDetails: AskTaxDetails
    closedDate: String
    contractNavigation: ContractNavigation
    createdDate: String
    holdings: UCITSHoldings
    """
    ID is on the format {accountId}:SAM_A.
    """
    id: ID
    legalEntity: String
    pensionType: String
    productArea: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    rights: [Rights!]
    savingAgreements: [UCITSSavingAgreement!]
    seller: String
    withdrawalAgreements: [WithdrawalAgreement!]
  }

  type UCITSAccountOnBehalfOfCustomer implements Contract & UCITSAccountAbstract {
    accountId: String
    accountName: String
    accountOperator: String
    accountType: String
    askTaxDetails: AskTaxDetails
    closedDate: String
    contractNavigation: ContractNavigation
    createdDate: String
    holdings: UCITSHoldings
    """
    ID is on the format {accountId}:SAM_A.
    """
    id: ID
    legalEntity: String
    onBehalfOfCustomer: OnBehalfOfCustomer
    pensionType: String
    productArea: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    rights: [Rights!]
    savingAgreements: [UCITSSavingAgreement!]
    seller: String
    withdrawalAgreements: [WithdrawalAgreement!]
  }

  type UCITSHoldings {
    cashHoldings: CashHoldings
    fundHoldings: [FundHoldings!]
    fundHoldingsTotal: FundHoldingsTotal
  }

  """
  # UCITS Account

  #### OIDC scopes
  * Realm storebrand  - storebrand.funds.private
  * Realm corporate   - TODO
  """
  type UCITSOrganizationAccount implements Contract & UCITSAccountAbstract {
    accountId: String
    accountName: String
    accountOperator: String
    accountType: String
    askTaxDetails: AskTaxDetails
    closedDate: String
    contractNavigation: ContractNavigation
    createdDate: String
    holdings: UCITSHoldings
    """
    ID is on the format {accountId}:SAM_A.
    """
    id: ID
    legalEntity: String
    organization: Organization
    pensionType: String
    productArea: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    rights: [Rights!]
    savingAgreements: [UCITSSavingAgreement!]
    seller: String
    withdrawalAgreements: [WithdrawalAgreement!]
  }

  type UCITSSavingAgreement {
    account: String
    activeState: ActiveState
    cashAmount: Float
    currency: String
    dayInMonth: Int
    debtorInfo: DebtorInfo
    deferred: [Deferred!]
    funds: [Fund!]
    id: String
    months: [Int!]
    name: String
    startDate: String
    stopDate: String
  }

  type UnavailablePensionProvider {
    """
    The category for the product. Values are the same as for the contracts but privatAFP and folketrygd are present when those cannot be fetched
    """
    category: String
    """
    If the pension provider is completely unavailable
    """
    completelyUnavailable: Boolean
    """
    The error code when privateAFP and folketryg fails
    """
    errorCode: String
    """
    Returned only if norskpensjon knows the number of missing contracts. Will be empty if completelyUnavailable is true
    """
    numberOfMissingContracts: Int
    """
    The pension provider which is unavailable
    """
    pensionProvider: PensionProvider
  }

  type UnrealizedProfit {
    amount: Float
    currency: String
  }

  type UsbCalculation {
    calculationCode: String
    calculationTxt: String
    lastCalculationDate: String
    validFromDate: String
  }

  type UsbTermDuration {
    termDuration: Int
    termDurationTxt: UsbTermDurationText
  }

  enum UsbTermDurationText {
    ANNUAL
    MONTHLY
    QUARTERLY
    SEMIANNUAL
  }

  type Voucher {
    transactionNumber: String
    transactions: [LifePayoutTransaction!]
  }

  type VouchersByArea {
    lastPayoutDate: String
    payoutArea: PayoutArea
    payoutMethod: PayoutMethod
    vouchers: [Voucher!]
  }

  type WithdrawalAgreement {
    account: String
    activeState: ActiveState
    bankAccountNumber: String
    currency: String
    dayInMonth: Float
    funds: [Fund!]
    id: String
    months: [Float!]
    name: String
    startDate: String
    stopDate: String
  }

  input WithdrawalPeriods {
    fullWithdrawal: FullWithdrawal
    partialWithdrawal: PartialWithdrawal
    salaryBeforeWithdrawal: Int
  }

  """
  # YTP - ytelsepensjon

  #### OIDC scopes
  * Realm storebrand  - storebrand.life.private
  * Realm corporate   - todo
  """
  type YtpContract implements Contract {
    annualPremium: Float
    applicableVacancyRate: Float
    calculatedNationalInsuranceBasicAmount: Float
    compressionLimits: [Float!]!
    contractNavigation: ContractNavigation
    """
    Contract number in USB
    """
    contractNumber: String
    """
    Contract type in USB
    """
    contractType: String
    coverages: Coverages
    """
    AgreementId to display to the customer. Not unique and can be changed at anytime
    """
    customerContractReference: String
    employedDate: String
    employer: Employer
    employmentPercentage: Float
    id: ID
    pensionQualifyingIncome: Float
    pensionSchemeInclusionDate: String
    """
    Customer number in USB
    """
    personCustomerNumber: String
    """
    S = Syk, D = Død, A = Arbeidsdyktig
    """
    personStatus: String
    productArea: String
    """
    Product code in USB
    """
    productCode: String
    """
    Object containing FMS key with english and norwegian texts
    """
    productNameFms: TextElement
    """
    Key to lookup in FMS
    """
    productNameFmsKey: String
    renewalDetails: RenewalDetail
    retirementAge: Int
    salary: Float
    salaryHistory: [SalaryHistory!]
    underPayout: Boolean
    usbCalculation: UsbCalculation
    usbTermDuration: UsbTermDuration
  }
`;
